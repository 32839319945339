import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import { addVisitCount } from '../utils/addVisitCount'; // Import funkcji
import 'highlight.js/styles/github-dark.css';

export const BlogPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (slug) {
      addVisitCount(slug)
    }
  }, [slug]);

  useEffect(() => {
    if (!slug) return;

    fetch(`/posts/${slug}.md`)
      .then((res) => res.text())
      .then((text) => setContent(text))
      .catch((err) => console.error('Error loading markdown file:', err));
  }, [slug]);

  return (
    <div className="bg-gray-100 text-gray-900 min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow p-8">
        <article className="prose lg:prose-xl max-w-none">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeHighlight]}
          >
            {content}
          </ReactMarkdown>
        </article>
      </main>
      <Footer />
    </div>
  );
};
