import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {fetchVisitCount} from "../utils/addVisitCount";

type PostTileProps = {
    slug: string;
    image: string;
    title: string;
    description: string;
    date: string;
    tags: string[];
};

export const PostTile: React.FC<PostTileProps> = ({slug, image, title, description, date, tags}) => {
    const [visitCount, setVisitCount] = useState<number>(0);

    useEffect(() => {
        const handleVisit = async () => {
            const visits = await fetchVisitCount(slug);
            setVisitCount(visits);
        };

        handleVisit();
    }, [slug]);

    return (
        <Link
            to={`/blog/${slug}`}
            className="block bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
        >
            <img src={image} alt={title} className="w-full h-36 object-cover"/>
            <div className="p-4">
                <h2 className="text-2xl font-bold mb-2">{title}</h2>
                <p className="text-gray-700 mb-4">{description}</p>
                <div className="text-sm text-gray-500">
                    <p className="mb-2">{date}</p>
                    <div className="flex flex-wrap space-x-2 mb-2">
                        {tags.map((tag, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-700"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                    <p className="text-blue-600 font-semibold">Odwiedziny: {visitCount}</p>
                </div>
            </div>
        </Link>
    );
};
