import React, { useState } from 'react';
import { Header } from '../components/Header';
import { LogoTitleSection } from '../components/LogoTitleSection';
import { PostTile } from '../components/PostTile';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';
import { FaInfoCircle } from 'react-icons/fa';

export const Home: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const posts = [
    {
      slug: 'post1',
      image: require('../assets/blog-image/post1.png'),
      title: 'Pierwszy Post',
      description: 'Tutaj zaczynamy krótką opowieścią :)',
      date: '3 Październik 2024',
      tags: ['Tech', 'Story', 'Ja'],
    },
    {
      slug: 'post2',
      image: 'https://via.placeholder.com/150',
      title: 'Post Title 2',
      description: 'This is a short description of the post.',
      date: 'August 28, 2024',
      tags: ['3D Printing', 'Design', 'Innovation'],
    },
    {
      slug: 'post3',
      image: 'https://via.placeholder.com/150',
      title: 'Post Title 3',
      description: 'This is a short description of the post.',
      date: 'August 29, 2024',
      tags: ['Electronics', 'DIY', 'Gadgets'],
    },
    {
      slug: 'post4',
      image: 'https://via.placeholder.com/150',
      title: 'Post Title 4',
      description: 'This is a short description of the post.',
      date: 'August 30, 2024',
      tags: ['Python', 'Data Science', 'Machine Learning'],
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <Header />
      <LogoTitleSection />

      {/* Sidebar on small screens (below md) */}
      <div className="relative md:hidden p-4">
        {!isSidebarOpen && (
          <button
            onClick={toggleSidebar}
            className="relative flex items-center justify-center w-12 h-12 bg-navy text-white rounded-full mx-auto"
          >
            <FaInfoCircle size={20} />
          </button>
        )}

        {isSidebarOpen && (
          <div
            className={`transition-all duration-300 ease-in-out relative z-20 bg-white shadow-lg overflow-hidden ${
              isSidebarOpen ? 'h-full' : 'h-1/2'
            }`}
            onClick={toggleSidebar}
          >
            <div className={`transition-opacity duration-300 ease-in-out ${isSidebarOpen ? 'opacity-100' : 'opacity-50'}`}>
              <Sidebar />
            </div>
          </div>
        )}
      </div>

      {/* Main content and Sidebar for large screens (md and larger) */}
      <div className="flex flex-grow p-8">
        <div className="flex-grow">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            {posts.map((post, index) => (
              <PostTile
                key={index}
                slug={post.slug}
                image={post.image}
                title={post.title}
                description={post.description}
                date={post.date}
                tags={post.tags}
              />
            ))}
          </div>
        </div>

        {/* Sidebar for md and larger screens */}
        <div className="hidden md:block w-64 ml-6">
          <Sidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
};
