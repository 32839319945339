import Cookies from 'js-cookie';
import axios from 'axios';

export const addVisitCount = async (slug: string): Promise<void> => {
  const cookieKey = `visited-${slug}`;
  const hasVisited = Cookies.get(cookieKey);

  if (!hasVisited) {
    Cookies.set(cookieKey, 'true', { expires: 360, domain: 'djarominek.dev', secure: true });

    try {
      await axios.post(`https://api.djarominek.dev/log/blog/${slug}`, { slug });
      console.log('Visit logged successfully');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error(
            `Error logging visit: ${error.response.status} - ${error.response.data}`
          );
        } else if (error.request) {
          console.error('Error logging visit: No response received', error.request);
        } else {
          console.error('Error logging visit: Request setup failed', error.message);
        }
      } else {
        console.error('Unexpected error:', error);
      }
    }
  }
};

export const fetchVisitCount = async (slug: string) => {
  try {
    const response = await axios.get(`https://api.djarominek.dev/log/blog/${slug}`);
    return response.data.visits;
  } catch (error) {
    console.error('Error fetching visit count:', error);
    return 0;
  }
};